import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVisits } from "../common/useVisits";
import StorageManager from "../services/storage";
import { dateString } from "../types/common";
import CardContainer from "./CardContainer";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";

const VisitsPage: React.FC = () => {
  const { t } = useTranslation();

  const { visits, isLoading } = useVisits();
  const [upcomingIndex, setUpcomingIndex] = useState(-1);

  // useEffect(() => {
  //   const index = visits.findIndex(
  //     (visit) => new Date(visit.earliest_date) >= new Date()
  //   );
  //   setUpcomingIndex(index);
  // }, [visits.length]);

  function isPast(visit: VisitJsonApiBlockWithId): boolean {
    return false;
  }

  if (isLoading) {
    return Array.from(new Array(3)).map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        height={56}
        sx={{ borderRadius: 1, mb: 1 }}
      />
    ));
  }

  return (
    <CardContainer title={t("visits_scheduleTitle")}>
      <Timeline
        data-test-id="visits-timeline"
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {visits.map((visit, index) => (
          <TimelineItem data-test-id="visit" key={visit.id}>
            <TimelineSeparator>
              <TimelineConnector
                sx={index === 0 ? { visibility: "hidden" } : null}
              />
              <TimelineDot
                color={upcomingIndex === index ? "success" : undefined}
              >
                {isPast(visit) ? <EventAvailableIcon /> : <CalendarTodayIcon />}
              </TimelineDot>
              <TimelineConnector
                sx={
                  index === visits.length - 1 ? { visibility: "hidden" } : null
                }
              />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "12px", px: 2, my: "auto" }}>
              <Typography
                data-test-id="visit-name"
                variant="h6"
                component="span"
              >
                {visit.attributes.name}
              </Typography>

              {upcomingIndex === index && (
                <Typography
                  data-test-id="upcoming-visit"
                  sx={{
                    backgroundColor: "#2e7d32",
                    color: "white",
                    px: 1.3,
                    py: 0.7,
                    ml: 1,
                    borderRadius: 1,
                    position: "absolute",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "65%",
                    whiteSpace: "nowrap",
                  }}
                  variant="caption"
                  fontWeight="bold"
                  component="span"
                >
                  {t("visits_nextVisitTitle")}
                </Typography>
              )}
              <Typography data-test-id="visit-date">
                {dateString(visit)}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </CardContainer>
  );
};

export default VisitsPage;
