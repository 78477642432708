// LoginPage.tsx
import GetAppIcon from "@mui/icons-material/GetApp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Radio,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ForgetPinButton from "../common/ForgetPinButton";
import { getTermsAndConditionsLinks } from "../common/TermsAndConditionsContentProvider";
import { useErrorHandler } from "../common/hooks";
import LanguageButton from "../components/AppHeader/LanguageButton";
import ConfirmationDialog from "../components/ConfirmationDialog";
import FormErrorBox from "../components/PaymentMethods/FormErrorBox";
import StudyIDDialog from "../components/StudyIDDialog";
import { reset } from "../redux/store";
import { configService } from "../services/configService";
import StorageManager from "../services/storage";
import { getTheme } from "../themes";
import { LoginData, Token } from "../types/common";
import CardContainer from "./CardContainer";
import TermsAndConditions from "./TermsAndConditionsPage";
import ChangePINPage from "./ChangePinPage";
import { getErrorMsg } from "../common/helpers";
import { setLanguages, setProfile } from "../redux/slices/authSlice";
import { setPaymentMethod } from "../redux/slices/paymentMethodsSlice";
import {
  useLoginAsParticipant,
  useOnboardAParticipant,
} from "../api/client/participant/participant";
import { ParticipantAttributes } from "../api/model/resources-participants.yml";
import { ServerError } from "../api/model";
import { nmibleAPI } from "../api/mutator/axios-instance";

const loginLogo = getTheme().loginLogo;
const logoHeight = getTheme().logoHeight;
const defaultRedirectPath = "/payments";
const pathToIgnore = "/submit/";

export default function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();
  const location = useLocation();

  const [isNewUser, setIsNewUser] = useState(true);
  const [userType, setUserType] = useState("participant");
  const [participantId, setParticipantId] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openStudyIDDialog, setOpenStudyIDDialog] = useState(false);
  const [studyID, setStudyID] = useState("");
  const [error, setError] = useState<string>("");
  const [showPIN, setShowPIN] = useState(false);
  const [requiresPinChange, setRequiresPinChange] = useState(false);
  const state: { isInactive?: boolean; from?: Location } = location.state || {};
  const isInactivityRedirect = state.isInactive ?? false;
  let callbackURL: string = state.from?.pathname ?? defaultRedirectPath;

  const {
    mutate: sendLoginRequest,
    isPending: isLoginPending,
    isError: isLoginFailed,
  } = useLoginAsParticipant({
    mutation: {
      onError: (error) => {
        console.log(error);
        handleFailure(error);
      },
      onSuccess: (data) => {
        const participantData = data.data?.attributes;
        if (!participantData) return;
        handleAuthSuccess(participantData);
      },
    },
  });

  const {
    mutate: sendOnboardRequest,
    isPending: isOnboardPending,
    isError: isOnboardFailed,
  } = useOnboardAParticipant({
    mutation: {
      throwOnError: true,
      onError: (error) => {
        handleFailure(error);
      },
      onSuccess: (data) => {
        sendLoginRequest({
          data: { clientId: participantId.trim(), pin: pin.trim() },
        });
      },
    },
  });

  function handleFailure(error: ServerError) {
    const errorMsg = getErrorMsg(t, error);
    setError(errorMsg);
  }

  function handleAuthSuccess(participantData: ParticipantAttributes) {
    const tokenData: Token = {
      access_token: participantData.access,
      requiresPinChange: participantData.requiresPinChange,
    };
    StorageManager.setTokenData(tokenData);
    if (tokenData.requiresPinChange) setRequiresPinChange(true);
    else performPostLoginActions();
  }

  if (callbackURL && callbackURL.toString().includes(pathToIgnore)) {
    callbackURL = defaultRedirectPath;
  }

  useEffect(() => {
    if (isInactivityRedirect) {
      const loginInformation = StorageManager.getLoginInformation();

      setIsNewUser(false);
      setTermsAccepted(true);
      setParticipantId(loginInformation?.clientId || "");
      setYearOfBirth(loginInformation?.yearOfBirth?.toString() || "");
    }
  }, []);

  useEffect(() => {
    reset();
    if (!isInactivityRedirect) StorageManager.clear();
  }, []);

  const closeStudyIDDialog = () => {
    setOpenStudyIDDialog(false);
    setStudyID("");
  };

  const handleStudyIDSubmit = () => {
    if (studyID.trim() === "") {
      return;
    }

    login();
    closeStudyIDDialog();
  };

  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true);

    return false;
  };

  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const handleDownloadPdf = () => {
    window.open(getTermsAndConditionsLinks().pdf, "_blank");
  };

  const handleClickShowPIN = () => {
    setShowPIN((show) => !show);
  };

  const isFormValid = useMemo(() => {
    const trimmedParticipantId = participantId.trim();

    if (!trimmedParticipantId) {
      return false;
    }

    if (!pin.trim()) {
      return false;
    }

    if (isNewUser) {
      if (confirmPin.trim() === "" || pin !== confirmPin) {
        return false;
      }

      if (userType === "participant") {
        const trimmedYearOfBirth = yearOfBirth.trim();

        if (trimmedYearOfBirth === "") {
          return false;
        }

        const currentYear = new Date().getFullYear();
        const year = parseInt(trimmedYearOfBirth, 10);
        if (isNaN(year) || year > currentYear) {
          return false;
        }
      }
    }

    if (!termsAccepted) {
      return false;
    }

    return true;
  }, [participantId, confirmPin, yearOfBirth, pin, isNewUser, termsAccepted]);

  const executeLoginAttempt = (loginData: LoginData) => {
    if (loginData.isNewUser)
      sendOnboardRequest({
        data: {
          clientId: loginData.clientId,
          yearOfBirth: loginData.yearOfBirth ?? 0,
          pin: loginData.pin,
          pin_confirmation: loginData.pin_confirmation ?? "",
        },
      });
    else
      sendLoginRequest({
        data: { clientId: loginData.clientId, pin: loginData.pin },
      });
  };

  const login = () => {
    const data: LoginData = {
      clientId: participantId.trim(),
      pin: pin.trim(),
      pin_confirmation: confirmPin.trim(),
      yearOfBirth: parseInt(yearOfBirth),
      isNewUser: isNewUser,
    };

    let loginDataToSave = JSON.parse(JSON.stringify(data));
    loginDataToSave.userType = userType;
    delete loginDataToSave.pin;

    StorageManager.setLoginInformation(loginDataToSave);

    executeLoginAttempt(data);
  };

  const handleContinueClick = async (event: any) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    login();
  };

  const performPostLoginActions = async () => {
    try {
      const { data: languagesData } = await nmibleAPI.get("/languages");
      if (!languagesData?.data?.length) return;
      dispatch(setLanguages(languagesData.data));
      const { data: userData } = await nmibleAPI.get("/participant/auth/who", {
        params: {
          include: "language,arm,arm.site,arm.site.study",
        },
      });

      dispatch(setProfile(userData));
      const paymentMethodId =
        userData?.data?.relationships.paymentMethods?.data?.[0].id;
      if (paymentMethodId) {
        dispatch(setPaymentMethod(paymentMethodId));
        navigate(callbackURL, { replace: true });
      } else {
        navigate("/new-card-onboarding");
      }
    } catch (error) {
      console.log("Failed to execute post login actions", error);
    }
  };

  const cancelRelogin = () => {
    StorageManager.removeLoginInformation();

    setIsNewUser(true);
    setTermsAccepted(false);
    setUserType("participant");
    setParticipantId("");
    setYearOfBirth("");
    setPin("");
    navigate("/login");
  };

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: 800, margin: "auto" }}
    >
      <img
        data-test-id="logo"
        src={loginLogo}
        alt={import.meta.env.VITE_CLIENT + " Logo"}
        style={{ height: logoHeight, marginBottom: "16px" }}
      />

      <Grid>
        <CardContainer
          title={
            !isInactivityRedirect
              ? t("virtualCard_continueButtonTitle")
              : t("session_expired")
          }
          subtitle={t("payment_dynamic_description")}
          topRightContent={
            <LanguageButton color={theme.palette.text.secondary} />
          }
        >
          {(isLoginFailed || isOnboardFailed) && <FormErrorBox error={error} />}

          <form>
            {!isInactivityRedirect && (
              <>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel
                    data-test-id="new-or-existing-label"
                    component="legend"
                  >
                    {t("login_new_or_existing")}
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      data-test-id="new-user"
                      control={
                        <Radio
                          checked={isNewUser}
                          onChange={() => setIsNewUser(true)}
                        />
                      }
                      label={t("auth_register", {
                        client: configService.config.clientName,
                      })}
                    />
                    <FormControlLabel
                      data-test-id="existing-user"
                      control={
                        <Radio
                          checked={!isNewUser}
                          onChange={() => setIsNewUser(false)}
                        />
                      }
                      label={t("auth_signIn")}
                    />
                  </FormGroup>
                </FormControl>

                <TextField
                  data-test-id="participant-textfield"
                  label={t("auth_participantIdNumber")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={participantId}
                  name="username"
                  onChange={(e) => setParticipantId(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />

                {isNewUser && (
                  <TextField
                    data-test-id="dateOfBirth-textfield"
                    label={t("auth_accountActivation_dateOfBirth")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={yearOfBirth}
                    name="year_of_birth"
                    onChange={(e) => setYearOfBirth(e.target.value)}
                    inputProps={{ maxLength: 4 }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </>
            )}

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {isNewUser ? t("auth_pin") : t("enterPin_title")}
              </InputLabel>

              <OutlinedInput
                onChange={(e) => setPin(e.target.value)}
                value={pin}
                data-test-id="pin-textfield"
                id="outlined-adornment-password"
                type={showPIN ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPIN}
                      edge="end"
                    >
                      {showPIN ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={isNewUser ? t("auth_pin") : t("enterPin_title")}
              />
            </FormControl>

            {isNewUser && (
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel htmlFor="outlined-adornment-confirm-password">
                  {t("confirmPin_title")}
                </InputLabel>

                <OutlinedInput
                  onChange={(e) => setConfirmPin(e.target.value)}
                  value={confirmPin}
                  data-test-id="confirm-pin-textfield"
                  id="outlined-adornment-confirm-password"
                  type={showPIN ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPIN}
                        edge="end"
                      >
                        {showPIN ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("confirmPin_title")}
                />
              </FormControl>
            )}

            {!isInactivityRedirect && (
              <FormControlLabel
                control={
                  <Checkbox
                    data-test-id="accept-tc-checkbox"
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    name="termsAndConditions"
                    color="primary"
                  />
                }
                label={
                  <Typography
                    data-test-id="accept-tc-label"
                    variant="subtitle1"
                  >
                    {t("accept")} &nbsp;
                    <Typography
                      data-test-id="accept-tc-link"
                      variant="subtitle1"
                      component={Link}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenTermsDialog();
                      }}
                    >
                      {t("terms_termsAndConditions")}.
                    </Typography>
                  </Typography>
                }
              />
            )}

            <LoadingButton
              data-test-id="submit-button"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{ mt: 2, mb: 2 }}
              loading={isLoginPending || isOnboardPending}
              onClick={handleContinueClick}
              disabled={!isFormValid} // Button will be disabled if form is not valid
            >
              {t("continue_text")}
            </LoadingButton>

            {isNewUser && (
              <ForgetPinButton title={t("participantIdHelp_subtitle")} />
            )}

            {(isInactivityRedirect || !isNewUser) && <ForgetPinButton />}

            {isInactivityRedirect && (
              <Button
                data-test-id="cancel-button"
                fullWidth
                onClick={() => cancelRelogin()}
                sx={{
                  color: "red",
                }}
              >
                {t("cancel")}
              </Button>
            )}
          </form>
        </CardContainer>
      </Grid>

      {/* Terms and Conditions Dialog */}
      <ConfirmationDialog
        testId="tc-dialog"
        maxWidth="md"
        showCancelIcon={true}
        fullWidth={true}
        open={openTermsDialog}
        title={t("terms_termsAndConditions")}
        message={
          <span
            style={{
              padding: 0,
              height: "60vh",
              overflow: "hidden",
              display: "block",
            }}
          >
            <TermsAndConditions />
          </span>
        }
        confirmButtonText={t("button_download_pdf")}
        confirmButtonIcon={<GetAppIcon />}
        onConfirm={handleDownloadPdf}
        cancelButtonText={t("button_close")}
        onCancel={handleCloseTermsDialog}
        hideConfirmation={!getTermsAndConditionsLinks().pdf}
      />

      {/* Study ID Dialog */}
      <StudyIDDialog
        open={openStudyIDDialog}
        onClose={closeStudyIDDialog}
        studyID={studyID}
        setStudyID={setStudyID}
        onSubmit={handleStudyIDSubmit}
      />

      {/* Change PIN Dialog */}

      <Dialog
        open={requiresPinChange}
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
        onClose={(reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setRequiresPinChange(false);
          }
        }}
      >
        <ChangePINPage
          isDialogMode={true}
          onSuccess={() => {
            setRequiresPinChange(false);
            performPostLoginActions();
          }}
        />
      </Dialog>
    </Box>
  );
}
