import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { nmibleAPI as api } from "../api/mutator/axios-instance";
import { rejectStipends } from "../api/stipendsServices";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
import { showToast } from "../redux/slices/toastSlice";
import CardContainer from "./CardContainer";

function RejectStipendPayment() {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");

  usePageTitle(t("claim_stipend_decline_button"));

  const handleContinueClick = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    api(rejectStipends(parseInt(id ?? ""), notes))
      .then(() => {
        dispatch(showToast("Payment successfully declined!"));
        navigate("/payments");
      })
      .catch((error) => {
        handleServerError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CardContainer>
      <Typography data-test-id="decline-description" variant="body1">
        {t("claim_stipend_decline_description")}
      </Typography>
      <TextField
        data-test-id="notes-textfield"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        margin="normal"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />

      <LoadingButton
        data-test-id="submit-button"
        type="submit"
        variant="contained"
        color="secondary"
        fullWidth
        size="large"
        sx={{ mt: 2 }}
        loading={isLoading}
        onClick={handleContinueClick}
      >
        {t("claim_stipend_declineButton")}
      </LoadingButton>
    </CardContainer>
  );
}

export default RejectStipendPayment;
