import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setExpenseTypes, setProfile } from "../redux/slices/authSlice";
import ManageCard from "./card/ManageCard";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import SubmitClaimButton from "../components/Payment/SubmitClaimButton";
import StorageManager from "../services/storage";
import { useNavigate } from "react-router-dom";
import ProtectYourIdentity from "./ProtectYourIdentity";
import { ExpenseRowData, mockPendingStipends } from "../types/common";
import PendingStipendsList from "./PendingStipendsList";
import ExpensesList from "./ExpensesList";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getPageNumber } from "../common/helpers";
import { setPaymentMethod } from "../redux/slices/paymentMethodsSlice";
import { RootState } from "../redux/store";
import TaxAlert from "./Tax/TaxAlert";
import { useListAllTaxForms } from "../api/client/tax-forms/tax-forms";
import { useListAllExpenseTypes } from "../api/client/expense-types/expense-types";
import { nmibleAPI } from "../api/mutator/axios-instance";
import {
  ExpenseJsonApiBlockWithId,
  ExpenseRestoreResponseResponse,
} from "../api/model/resources-expenses.yml";
import { ExpenseTypeJsonApiBlockWithId } from "../api/model/resources-expense-types.yml";

function NewPaymentsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProtectYourIdentityOpen, setProtectYourIdentityOpen] =
    useState(false);

  const paymentMethodId = useSelector(
    (state: RootState) => state.paymentMethods.paymentMethod
  );
  const {
    data: taxFormsData,
    isPending: isLoadingTaxForms,
    error: taxFormsError,
  } = useListAllTaxForms();

  // const [expenses, setExpenses] = useState<ExpenseRowData[]>([]);
  // const { ref, inView } = useInView();

  const { t } = useTranslation();

  const {
    data: expenseTypesData,
    error: expenseTypesError,
    isLoading: loadingExpenseTypesData,
  } = useListAllExpenseTypes();

  useEffect(() => {
    if (!paymentMethodId) navigate("/new-card-onboarding");
  }, [paymentMethodId]);

  useEffect(() => {
    if (!expenseTypesData?.data) return;

    dispatch(setExpenseTypes(expenseTypesData.data));
  }, [expenseTypesData]);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching: loadingPayments,
    isFetchingNextPage: loadingNextPayments,
    status,
  } = useInfiniteQuery({
    queryKey: ["expenses"],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await nmibleAPI.get("/expenses", {
        params: {
          "page[number]": pageParam.toString(),
          "page[size]": "10",
          include: "receipt,expenseItems.expenseType",
        },
      });

      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const nextLink = lastPage?.links?.next;
      if (nextLink) {
        const pageNumber = getPageNumber(nextLink);
        if (pageNumber > allPages.length) return pageNumber;
      }
      return undefined;
    },
  });

  function mapExpensesData() {
    const items = data?.pages.flatMap(
      (page) =>
        page?.data?.map((item: ExpenseJsonApiBlockWithId) => {
          const expenseTypeId =
            item.relationships["expenseItems.expenseType"]?.data?.[0]?.id;
          return {
            id: item.id!,
            createdAt: formattedDate(item.attributes.expenseDate!),
            type: getExpenseName(expenseTypeId) ?? "",
            amount: item.attributes.amount ?? "0.00",
            status: item.attributes.status,
            receipt_url: "https://example.com",
          };
        }) ?? []
    );
    console.log("mapped items", items);
    return items;
  }

  function loadMoreData() {
    if (hasNextPage && !loadingPayments) {
      fetchNextPage();
    }
  }

  function getExpenseName(expenseTypeId: string): string {
    const expenseTypesData = data?.pages[0]?.included || [];
    const expenseName: string = expenseTypesData.find(
      (item: ExpenseTypeJsonApiBlockWithId) => item.id === expenseTypeId
    )?.attributes?.name;
    return expenseName || "";
  }

  function paymentRowsSkeleton() {
    return (
      <Paper data-test-id="payments-skeleton" elevation={0}>
        <Skeleton
          variant="rounded"
          height={88}
          style={{ marginBottom: "10px" }}
        />
      </Paper>
    );
  }

  function paymentsSectionTitleSkeleton() {
    return (
      <Skeleton
        variant="text"
        height={40}
        width={"30%"}
        sx={{
          mt: 2,
          mb: 1,
        }}
      />
    );
  }

  const paymentsSectionTitle = (title: string) => {
    if (isLoading()) return paymentsSectionTitleSkeleton();
    return sectionTitle(title);
  };

  const sectionTitle = (title: string) => {
    return (
      <Typography
        sx={{
          mt: 2,
          mb: 1,
        }}
        variant="subtitle2"
        color="text.secondary"
        fontWeight="600"
      >
        {title}
      </Typography>
    );
  };

  function isLoading() {
    return (
      (loadingPayments && !loadingNextPayments) ||
      loadingExpenseTypesData ||
      isLoadingTaxForms
    ); // || loadingACHTransactions
  }
  function formattedDate(date: string | Date) {
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
    });
  }

  function paymentRows() {
    const expenses = mapExpensesData();
    if (!expenses || expenses.length === 0) {
      return emptyData();
    }

    return (
      <>
        <ExpensesList data={expenses} loadMoreData={loadMoreData} />
        {loadingNextPayments && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
      </>
    );
  }

  function emptyData() {
    return (
      <Card variant="outlined" sx={{ border: "none" }}>
        <CardContent>
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25vh",
              textAlign: "center",
            }}
          >
            {t("payments_empty")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  function onSubmitMileage() {
    navigate("/payment/submit/mileage/visit");
  }

  function onSubmitReceipt() {
    if (StorageManager.hideProtectYourIdentityMessage()) {
      navigate("/receipt/submit/upload");
    } else {
      setProtectYourIdentityOpen(true);
    }
  }

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "transparent",
        minHeight: "calc(100vh - 56px - 32px)",
        paddingBottom: "50px",
      }}
    >
      {/* New Card Balance */}
      {/* {paymentMethodsData?.available_payment_methods.some(
        (item) => item.payment_type === PaymentType.VirtualCardV2
      ) && <ManageCard />} */}
      <ManageCard />
      {/* Card  Transactions */}
      {/* {cardActivitiesSectionTitle(t("Card activity"))} */}
      {/* {isLoading()
        ? paymentRowsSkeleton()
        : (achData ?? []).length > 0 && (
            <TransactionsList transactions={achData ?? []} />
          )} */}

      {/* Payments */}
      {!isLoading() && (
        <>
          <TaxAlert taxForms={taxFormsData?.data ?? []} />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-end",
              },
            }}
          >
            <SubmitClaimButton
              onSubmitMileage={onSubmitMileage}
              onSubmitReceipt={onSubmitReceipt}
            />
          </Box>
          <PendingStipendsList stipends={mockPendingStipends} />
        </>
      )}

      {paymentsSectionTitle(t("payments"))}
      {isLoading()
        ? paymentRowsSkeleton()
        : data?.pages.length === 0 || data?.pages[0]?.data?.length === 0
        ? emptyData()
        : paymentRows()}

      <ProtectYourIdentity
        isOpen={isProtectYourIdentityOpen}
        onClose={() => setProtectYourIdentityOpen(false)}
        onContinue={() => {
          setProtectYourIdentityOpen(false);
          navigate("/receipt/submit/upload");
        }}
      ></ProtectYourIdentity>
    </Paper>
  );
}

export default NewPaymentsPage;
