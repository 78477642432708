import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../common/TitleContext";
import { useErrorHandler } from "../../common/hooks";
import CardContainer from "../CardContainer";
import { getErrorMsg } from "../../common/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCreateTaxForm } from "../../api/client/tax-forms/tax-forms";

export enum Delivery {
  paper = "paper",
  electronic = "electronic",
}

function CreateTaxFormPage() {
  const { t } = useTranslation();
  const [deliveryMethod, setDeliveryMethod] = useState<Delivery>(
    Delivery.electronic
  );
  const claimant_id = useSelector(
    (state: RootState) => state.auth.profile?.claimant_id
  );
  const navigate = useNavigate();
  const { handleServerError } = useErrorHandler();

  usePageTitle(t("tax_form_title"));

  const {
    mutate,
    isPending: isLoading,
    isError,
    isSuccess,
  } = useCreateTaxForm({
    mutation: {
      onError: (error, variables, context) => {
        const errorMsg = getErrorMsg(t, error);
        // setServerError(errorMsg);
        // handleServerError(error);
      },
      onSuccess: (data, variables, context) => {
        const attributes = data.data?.attributes;
        if (!attributes) return;
        const url = attributes.formUrl;
        navigate(`/tax/form?url=${url}`);
      },
    },
  });

  const handleSubmit = () => {
    mutate({
      data: {
        data: {
          type: "tax-forms",
          attributes: {
            deliveryMethod: deliveryMethod,
          },
          relationships: {
            participant: {
              data: {
                type: "participants",
                id: `${claimant_id}`,
              },
            },
          },
        },
      },
    });
  };

  function radioComponent(value: Delivery, title: string, description: string) {
    return (
      <label
        style={{
          display: "block",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => setDeliveryMethod(value)}
      >
        <Box
          sx={{
            border: 1,
            borderColor: "grey.400",
            borderRadius: 1,
            padding: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          <FormControlLabel
            value={value}
            control={
              <Radio sx={{ pl: 0 }} checked={deliveryMethod === value} />
            }
            label={<Typography variant="h6">{title}</Typography>}
            sx={{ margin: 0 }}
          />
          <Typography variant="body2">{description}</Typography>
        </Box>
      </label>
    );
  }

  return (
    <CardContainer>
      <Box>
        <FormControl component="fieldset" sx={{ mb: 3, mt: 2 }}>
          <FormLabel component="legend">
            {t("tax_choose_delivery_method")}
          </FormLabel>
          <RadioGroup
            value={deliveryMethod}
            onChange={(e) => setDeliveryMethod(e.target.value as Delivery)}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              mt: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                {radioComponent(
                  Delivery.electronic,
                  t("tax_electronic"),
                  t("tax_electronic_description")
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                {radioComponent(
                  Delivery.paper,
                  t("tax_paper"),
                  t("tax_paper_description")
                )}
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>

      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        sx={{ mt: 2 }}
        loadingPosition="start"
        loading={isLoading}
        onClick={handleSubmit}
      >
        {t("continue_text")}
      </LoadingButton>
    </CardContainer>
  );
}

export default CreateTaxFormPage;
