import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  Box,
  Chip,
  Avatar,
  Stack,
  Alert,
  AlertTitle,
  Skeleton,
} from "@mui/material";
import {
  DirectionsCar as MileageIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import CardContainer from "./CardContainer";
import { useTranslation } from "react-i18next";
import { useReadExpenseById } from "../api/client/expenses/expenses";

const ExpenseDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  //   if (!id) return;
  //   const expense = mockExpenses.find((exp) => exp.id === id);

  const { data, isLoading } = useReadExpenseById(id!, {
    include:
      "expenseItems,receipt,participantVisit.visit,expenseItems.expenseType",
  });

  if (isLoading) {
    return <ExpenseDetailsSkeleton />;
  }

  if (!data?.data) return <Navigate to="/payments" />;

  const expenseType: string =
    data?.included?.filter((item: any) => item.type === "expense-types")?.[0]
      ?.attributes.name ?? "";
  const visitName: string =
    data?.included?.filter((item: any) => item.type === "visits")?.[0]
      ?.attributes.name ?? "";
  const receipt_url: string =
    data?.included?.filter((item: any) => item.type === "receipts")?.[0]
      ?.attributes.uri ?? "";
  const expense = data?.data;

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "warning";
    }
  };

  return (
    <CardContainer
      title={`${expenseType} Expense`}
      subtitle={t("claimDetail_claimWithId", { "0": expense.id })}
      icon={
        <Avatar sx={{ mr: 2 }}>
          {expenseType === "Mileage" ? <MileageIcon /> : <ReceiptIcon />}
        </Avatar>
      }
      topRightContent={
        receipt_url && <ReceiptImagePreview source={receipt_url} />
      }
    >
      <Stack spacing={2}>
        {expense.attributes.notes &&
          expense.attributes.status === "rejected" && (
            <Alert severity="error">
              <AlertTitle>{t("claimDetail_rejectionReason")}</AlertTitle>
              {expense.attributes.notes}
            </Alert>
          )}
        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Date</Typography>
          <Typography variant="h6">
            {new Date(expense.attributes.expenseDate).toLocaleDateString()}
          </Typography>
        </Stack>

        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Visit</Typography>
          <Typography variant="h6">{visitName}</Typography>
        </Stack>

        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Amount</Typography>
          <Typography variant="h6">${expense.attributes.amount}</Typography>
        </Stack>

        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Status</Typography>
          <Chip
            label={t(expense.attributes.status)}
            color={
              getStatusColor(expense.attributes.status) as
                | "success"
                | "error"
                | "warning"
            }
          />
        </Stack>
      </Stack>
    </CardContainer>
  );
};

const ExpenseDetailsSkeleton: React.FC = () => {
  return (
    <CardContainer
      title={" "}
      subtitle={" "}
      icon={<Skeleton variant="circular" width={40} height={40} />}
      topRightContent={
        <Box
          sx={{
            width: "100px",
            height: "auto",
            overflow: "hidden",
            p: "20px 20px 0 0",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
      }
    >
      <Stack spacing={2}>
        {[...Array(4)].map((index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              <Skeleton width={100} />
            </Typography>
            <Typography variant="h6">
              <Skeleton width={60} />
            </Typography>
          </Stack>
        ))}
      </Stack>
    </CardContainer>
  );
};

export default ExpenseDetails;
