import { Alert, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import paymentService from "../api/paymentService";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useErrorHandler } from "../common/hooks";
import { useVisits } from "../common/useVisits";
import VehicleDetailsPreview from "../components/VehicleDetailsPreview";
import { clearMileageClaimData } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { dateString, isElectricVehicle } from "../types/common";
import {
  ConversionResponse,
  ConvertCurrencyData,
  MileageClaimData,
  VehicleQuestion,
} from "../types/paymentTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";
import { nmibleAPI as api } from "../api/mutator/axios-instance";
import { useListAllExpenseTypes } from "../api/client/expense-types/expense-types";
import { useCreateNewExpense } from "../api/client/expenses/expenses";
import { useCreateNewParticipantVisit } from "../api/client/participant-visits/participant-visits";
import { useCreateNewExpenseItem } from "../api/client/expense-items/expense-items";

const MileageSummaryPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();

  const [visit, setVisit] = useState<VisitJsonApiBlockWithId | null>(null);
  // const [isAccepting, setIsAccepting] = useState(false);
  const [conversionData, setConversionData] =
    useState<ConversionResponse | null>(null);

  usePageTitle(t("claim_chooseClaimMileage"));

  const claim: MileageClaimData | null = useSelector(
    (state: RootState) => state.claims.mileageClaim
  );

  const questions = useSelector(
    (state: RootState) => state.auth.profile?.distance_rule_screens ?? []
  );

  const profileCurrency = useSelector(
    (state: RootState) => state.auth.profile?.currency ?? "USD"
  );

  const distance_unit = useSelector(
    (state: RootState) => state.auth.profile?.distance_unit
  );

  const latestPaymentAccount = useSelector(
    (state: RootState) => state.paymentMethods.latestPaymentAccount
  );

  const paymentCurrencySymbol = getSymbol(
    latestPaymentAccount?.payment_currency ?? "USD"
  );

  const { visits, isLoading: isLoadingVisits } = useVisits();

  const handleCurrencyConversion = async () => {
    if (!latestPaymentAccount) return;
    if (!claim?.total_amount) return;
    if (profileCurrency === latestPaymentAccount?.payment_currency) return;

    const conversionInput: ConvertCurrencyData = {
      source: profileCurrency,
      to: latestPaymentAccount.payment_currency,
      amount: claim.total_amount,
      country_to_pay: latestPaymentAccount.payment_country_iso,
    };

    try {
      const response = await api(
        paymentService.convertCurrency(conversionInput)
      );
      setConversionData(response.data);
    } catch (error: any) {
      handleServerError(error);
    }
  };

  useEffect(() => {
    handleCurrencyConversion();
  }, [profileCurrency, latestPaymentAccount]);

  useEffect(() => {
    if (claim?.visit_id) {
      let visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });
      if (visit) setVisit(visit);
    }
  }, [claim, visits.length]);

  const [mileageType, setMileageType] = useState("");
  const {
    data: expenseTypes,
    error,
    isLoading: isLoadingExpenseTypes,
  } = useListAllExpenseTypes();

  useEffect(() => {
    const mileageId = expenseTypes?.data?.filter(
      (item) => item.attributes.name?.toLowerCase() === "mileage"
    )?.[0]?.id;
    if (mileageId) setMileageType(mileageId);
  }, [expenseTypes]);

  const {
    mutate: createExpense,
    isPending: isAccepting,
    isError,
  } = useCreateNewExpense({
    mutation: {
      onError: (error) => {
        console.error("Error sending your claim:", error);
      },
      onSuccess: (data) => {
        createItems({
          data: {
            data: {
              type: "expense-items",
              attributes: {
                amount: claim!.total_amount!.toFixed(2),
              },
              relationships: {
                expense: {
                  data: {
                    type: "expenses",
                    id: data.data?.id,
                  },
                },
                expenseType: {
                  data: {
                    type: "expense-types",
                    id: mileageType,
                  },
                },
              },
            },
          },
        });
      },
    },
  });

  const {
    mutate: createParticipantVisit,
    isPending: isCreatingParticipantVisit,
  } = useCreateNewParticipantVisit({
    mutation: {
      onError: (error) => {
        console.error("Error creating participant-visits:", error);
      },
      onSuccess: (data) => {
        // create expense
        createExpense({
          data: {
            data: {
              type: "expenses",
              attributes: {
                title: "Mileage Claim",
                status: "pending",
                expenseDate: claim?.visit_date!,
              },
              relationships: {
                participantVisit: {
                  data: {
                    type: "participant-visits",
                    id: data.data?.id,
                  },
                },
                currency: {
                  data: {
                    type: "currencies",
                    id: "1",
                  },
                },
              },
            },
          },
        });
      },
    },
  });

  const claimant_id = useSelector(
    (state: RootState) => state.auth.profile!.claimant_id
  );
  const armId = useSelector((state: RootState) => state.auth.arm!.id);

  const { mutate: createItems, isPending: isCreatingItems } =
    useCreateNewExpenseItem({
      mutation: {
        onError: (error) => {
          console.error("Error sending your claim:", error);
        },
        onSuccess: (data) => {
          dispatch(showToast(t("claim_toastConfirmationMessage")));
          dispatch(clearMileageClaimData());
          navigate("/payments");
        },
      },
    });

  const submitClaim = () => {
    createParticipantVisit({
      data: {
        data: {
          type: "participant-visits",
          attributes: visit!.attributes,
          relationships: {
            arm: {
              data: {
                type: "arms",
                id: armId,
              },
            },
            visit: {
              data: {
                type: "visits",
                id: visit!.id,
              },
            },
            participant: {
              data: {
                type: "participants",
                id: `${claimant_id}`,
              },
            },
          },
        },
      },
    });
  };

  const handleDeclinePayment = () => {
    dispatch(clearMileageClaimData());
    navigate("/");
  };

  if (!claim) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("cancel"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("reviewClaimDetail_submitMyClaim"),
      onClick: submitClaim,
      color: "primary",
      variant: "contained",
      loading: isAccepting || isCreatingItems || isCreatingParticipantVisit,
    },
  ];

  const isElectric = isElectricVehicle();

  const detailSections: DetailSection[] =
    claim.rateCalculationResults?.rates.flatMap((item) => {
      return [
        {
          title: t(distance_unit!),
          content: `${item.distance} ${distance_unit}`,
        },
        {
          title:
            distance_unit === "miles"
              ? t("mileageReview_RatePerMile")
              : t("mileageReview_RatePerKilometer"),
          subtitle: isElectric
            ? t("vehicleSummary_IncludesElectricVehicle")
            : undefined,
          content: `${getSymbol(profileCurrency)}${item.rate?.toFixed(2)}`,
        },
      ];
    }) ?? [];

  if (conversionData) {
    let conversionInfo = [
      {
        title: t("claim_originalTotal"),
        content: `${getSymbol(conversionData.original_currency)}${
          conversionData.original_amount
        }`,
      },
      {
        title: t("claim_conversionRate"),
        content: `${getSymbol(conversionData.original_currency)}1 = ${getSymbol(
          conversionData.requested_currency
        )}${conversionData.conversion_rate}`,
      },
    ];
    detailSections.push(...conversionInfo);
  }

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.attributes.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${paymentCurrencySymbol}${(
      conversionData?.amount_to_pay ?? claim.total_amount
    )?.toFixed(2)}`,
  };

  function vehicleDetails(
    questions: VehicleQuestion[]
  ): React.ReactNode | undefined {
    if (questions.length === 0) return undefined;
    return VehicleDetailsPreview(questions);
  }

  return (
    <>
      {(!mileageType || isLoadingExpenseTypes) && (
        <Alert severity="error">
          You can’t submit a fuel allowance expense because this option isn’t
          enabled for your study. If you believe this is an error, please
          contact the admin for assistance.
        </Alert>
      )}
      <SummaryPageUI
        title={t("claim_reviewYourClaim")}
        isLoading={isLoadingVisits || isLoadingExpenseTypes}
        showChangePaymentMethodButton={true}
        subtitle={t("claim_reviewSubtitle")}
        detailSections={detailSections}
        visitSection={visitSection}
        actions={actions}
        totalSection={totalSection}
        latestPaymentAccount={latestPaymentAccount}
        bottomContent={vehicleDetails(questions)}
      />
    </>
  );
};

export default MileageSummaryPage;
