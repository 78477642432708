import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useErrorHandler } from "../common/hooks";
import { useVisits } from "../common/useVisits";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import { clearReceiptClaimData } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { dateString } from "../types/common";
import {
  CategoryAmount,
  ConversionResponse,
  ReceiptClaimData,
} from "../types/paymentTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";
import { VisitJsonApiBlockWithId } from "../api/model/resources-visits.yml";
import { useCreateNewExpense } from "../api/client/expenses/expenses";
import { useCreateNewReceipt } from "../api/client/receipts/receipts";
import { useCreateNewParticipantVisit } from "../api/client/participant-visits/participant-visits";
import { useCreateNewExpenseItem } from "../api/client/expense-items/expense-items";

const ReceiptSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  usePageTitle(t("claim_chooseClaimReceipt"));

  const [visit, setVisit] = useState<VisitJsonApiBlockWithId | null>(null);
  const [conversionData, setConversionData] =
    useState<ConversionResponse | null>(null);

  const claim: ReceiptClaimData | null = useSelector(
    (state: RootState) => state.claims.receiptClaim
  );

  const receiptCurrency = useSelector(
    (state: RootState) => state.claims.receiptClaim?.currency ?? "USD"
  );

  const latestPaymentAccount = useSelector(
    (state: RootState) => state.paymentMethods.latestPaymentAccount!
  );
  const paymentCurrencySymbol = "$";

  const { visits, isLoading: isLoadingVisits } = useVisits();

  useEffect(() => {
    if (claim?.visit_id) {
      let visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });
      if (visit) setVisit(visit);
    }
  }, [claim, visits.length]);

  const [isAllItemsCreated, setIsAllItemsCreated] = useState(false);

  useEffect(() => {
    if (isAllItemsCreated) {
      dispatch(showToast(t("claim_toastConfirmationMessage")));
      dispatch(clearReceiptClaimData());
      navigate("/payments");
    }
  }, [isAllItemsCreated]);

  const {
    mutate: createExpense,
    isPending: isAccepting,
    isError,
  } = useCreateNewExpense({
    mutation: {
      onError: (error) => {
        console.error("Error sending your claim:", error);
      },
      onSuccess: async (data) => {
        if (!claim?.items || claim?.items?.length === 0) return;
        const expenseId = data.data?.id;
        for (const item of claim.items) {
          await mutationExpenseItems.mutateAsync({
            data: {
              data: {
                type: "expense-items",
                attributes: {
                  amount: item.amount!.toFixed(2),
                },
                relationships: {
                  expense: {
                    data: {
                      type: "expenses",
                      id: expenseId,
                    },
                  },
                  expenseType: {
                    data: {
                      type: "expense-types",
                      id: item.category,
                    },
                  },
                },
              },
            },
          });
        }

        upload({
          data: {
            data: {
              type: "receipts",
              attributes: {
                file: claim.receiptData?.split(",")[1],
              },
              relationships: {
                expense: {
                  data: {
                    type: "expenses",
                    id: expenseId,
                  },
                },
              },
            },
          },
        });
      },
    },
  });

  const { mutate: upload, isPending: isUploadingReceipt } = useCreateNewReceipt(
    {
      mutation: {
        onSuccess() {
          setIsAllItemsCreated(true);
        },
      },
    }
  );

  const {
    mutate: createParticipantVisit,
    isPending: isCreatingParticipantVisit,
  } = useCreateNewParticipantVisit({
    mutation: {
      onError: (error) => {
        console.error("Error creating participant-visits:", error);
      },
      onSuccess: (data) => {
        // create expense
        createExpense({
          data: {
            data: {
              type: "expenses",
              attributes: {
                title: "Receipt Claim",
                status: "pending",
                expenseDate: claim!.visit_date!,
              },
              relationships: {
                participantVisit: {
                  data: {
                    type: "participant-visits",
                    id: data.data?.id,
                  },
                },
                currency: {
                  data: {
                    type: "currencies",
                    id: "1",
                  },
                },
              },
            },
          },
        });
      },
    },
  });

  const claimant_id = useSelector(
    (state: RootState) => state.auth.profile!.claimant_id
  );
  const armId = useSelector((state: RootState) => state.auth.arm!.id);

  const mutationExpenseItems = useCreateNewExpenseItem({
    mutation: {
      onError: (error) => {
        console.error("Error sending your claim:", error);
      },
      onSuccess: (data) => {
        dispatch(showToast(t("claim_toastConfirmationMessage")));
        dispatch(clearReceiptClaimData());
        navigate("/payments");
      },
    },
  });

  const submitClaim = () => {
    createParticipantVisit({
      data: {
        data: {
          type: "participant-visits",
          attributes: visit!.attributes,
          relationships: {
            arm: {
              data: {
                type: "arms",
                id: armId,
              },
            },
            visit: {
              data: {
                type: "visits",
                id: visit!.id,
              },
            },
            participant: {
              data: {
                type: "participants",
                id: `${claimant_id}`,
              },
            },
          },
        },
      },
    });
  };

  const handleDeclinePayment = () => {
    dispatch(clearReceiptClaimData());
    navigate("/");
  };

  if (!claim) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("cancel"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("claim_SubmitAnExpense"),
      onClick: submitClaim,
      color: "primary",
      variant: "contained",
      loading:
        isAccepting ||
        isCreatingParticipantVisit ||
        mutationExpenseItems.isPending ||
        isUploadingReceipt,
    },
  ];
  const detailSections: DetailSection[] =
    claim.items?.map((item) => {
      return {
        title: item.value,
        content: `${getSymbol(receiptCurrency)}${item.amount?.toFixed(2)}`,
      };
    }) ?? [];

  if (conversionData) {
    let conversionInfo: DetailSection[] = [];
    if (claim.items!.length > 1) {
      conversionInfo.push({
        title: t("claim_originalTotal"),
        content: `${getSymbol(
          conversionData.original_currency
        )}${conversionData.original_amount.toFixed(2)}`,
      });
    }
    conversionInfo.push({
      title: t("claim_conversionRate"),
      content: `${getSymbol(conversionData.original_currency)}1 = ${getSymbol(
        conversionData.requested_currency
      )}${conversionData.conversion_rate}`,
    });

    detailSections.push(...conversionInfo);
  }

  function sumAmounts(visitCategories: CategoryAmount[]): number {
    return visitCategories.reduce((total, category) => {
      return total + (category.amount || 0);
    }, 0);
  }

  const total = conversionData?.amount_to_pay ?? sumAmounts(claim.items ?? []);

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.attributes.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("receiptClaim_amount_totalTitle"),
    content: `${paymentCurrencySymbol}${total.toFixed(2)}`,
  };

  return (
    <SummaryPageUI
      title={t("claim_reviewYourClaim")}
      isLoading={isLoadingVisits}
      showChangePaymentMethodButton={true}
      subtitle={t("claim_reviewSubtitle")}
      detailSections={detailSections}
      visitSection={visitSection}
      actions={actions}
      totalSection={totalSection}
      latestPaymentAccount={latestPaymentAccount}
      topRightContent={<ReceiptImagePreview />}
    />
  );
};

export default ReceiptSummaryPage;
