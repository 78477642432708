import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { renderFields } from "@highnoteplatform/card-viewer";
import styles from "./CardViewer.module.css";
import { useCardContext } from "./CardContext";
import { PaymentMethodVisualisationAttributes } from "../../../api/model/resources-payment-methods.yml";
import { useVisualisePaymentMethodById } from "../../../api/client/payment-methods/payment-methods";

const CardViewer: React.FC = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { setCustomToggleFunction } = useCardContext();
  const paymentMethodId = useSelector(
    (state: RootState) => state.paymentMethods.paymentMethod
  );

  if (!paymentMethodId) return;

  const {
    data: highnoteConfigData,
    error: highnoteConfigError,
    isLoading: loadingHighnoteConfig,
  } = useVisualisePaymentMethodById(paymentMethodId);

  useEffect(() => {
    let unmount: (() => void) | undefined;

    const renderCard = async (
      cardData: PaymentMethodVisualisationAttributes
    ) => {
      const result = await renderFields({
        clientToken: cardData.signature!,
        paymentCardId: cardData.cardId!,
        environment: "test",
        enableClipboard: false,
        onError: (error) => {
          console.log(error);
        },
        elements: {
          cardNumber: { selector: "#cardNumber", styles: { color: "white" } },
          cvv: { selector: "#cvv", styles: { color: "white" } },
          expirationDate: {
            selector: "#expirationDate",
            styles: { color: "white" },
          },
        },
      });

      unmount = result.unmount;
      setCustomToggleFunction(() => result.toggleCardNumberMask);
    };

    if (highnoteConfigData?.data?.attributes) {
      renderCard(highnoteConfigData.data.attributes);
    }

    return () => {
      if (unmount) {
        unmount();
      }
    };
  }, [highnoteConfigData]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        aspectRatio: 1.55,
        maxWidth: isLargeScreen ? 500 : "100%",
        minWidth: "316px",
        margin: "0 auto",
        borderRadius: "1rem",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.center}>
          <div className={styles.fakeCard}>
            <div id="cardNumber"></div>
            <div id="fakeCardBottomRow">
              <div id="cvv"></div>
              <div id="expirationDate"></div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default CardViewer;
